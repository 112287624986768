/**
 * Foursource page
 * https://foursource.com/pricing
 */

import * as React from "react";
import { withTrans } from "../i18n/withTrans";

import { pageTexts } from "../content/pricing";
import { plansTexts } from "../content/pages/plans";
import { associates } from "../content/pages/associatesPrincipal";

// components
import Layout from "../components/layout";
import TextImgBackgroundFull from "../components/textImgBackgroundFull";
import TitleSeparator from "../components/titleSeparator";
import Associates from "../components/_page/associates";
import PlansTabs from "../components/_page/_plansTabs";

class pricingPage extends React.Component {
  constructor(props) {
    super(props);
    this.t = props.t;
    this.text = pageTexts(this.t);
    this.plansText = plansTexts(this.t);
    this.associates = associates(this.t);
  }

  render() {
    return (
      <>
        <Layout pageName="pricing" whiteMenu={false}>
          <TextImgBackgroundFull info={this.text.topInfo}></TextImgBackgroundFull>
          <div className="paddingPricing pt-30 text-center">
            <TitleSeparator info={this.text.plansTitle}></TitleSeparator>
            <PlansTabs tabList={this.plansText.tabList} tabsPanel={this.plansText.tabContent} location={this.props.location}></PlansTabs>
          </div>
          <div className="mt-50 pt-75 topDiagonal backgroundGrey">
            <Associates info={this.associates.cardsInfo}></Associates>
          </div>
        </Layout>
      </>
    );
  }
}

export default withTrans(pricingPage);
