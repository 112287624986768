import CheckIcon from '../../images/icon/check.svg';
import Utm from '../../components/_global/utm';

export const plansTexts = (t) => {
  return {
    tabList: [t('pricing.buyers'), t('pricing.fabricSuppliers')],
    tabContent: [
      {
        topPlan: {
          title: {
            text: t('pricing.individual'),
            colorClass: 'colorDark',
            extraClass: 'font40 text-center',
          },
          text: {
            text: t('pricing.benefitsDigital'),
            colorClass: 'colorDark',
            extraClass: 'fontWeightBold text-center pb-30',
          },
          icon: {
            fontAwesome: false,
            src: CheckIcon,
            alt: t('homepage.checkAlt'),
          },
          separator: true,
          classSeparator: 'greenStyle mt-0',
          itemsClass: 'text-left pr-30 pl-30',
          customPBText: true,
          item: {
            text: [
              t('pricing.seeProfiles'),
              t('pricing.connectSuppliers'),
              t('pricing.manageStandards'),
              t('pricing.postRFQa'),
              t('pricing.createFavorite'),
            ],
            colorClass: 'colorDarkLight font18',
          },
        },
        bottomPlan: {
          title: {
            text: t('pricing.free'),
            colorClass: 'colorDark',
            extraClass: 'font40 text-center fontWeight800 m-0',
          },
          text: {
            text: t('pricing.noCard'),
            colorClass: 'colorDark',
            extraClass: 'fontWeightBold text-center pb-30',
          },
          separator: false,
        },
        cta: {
          text: t('menu.joinNow'),
          url:
            process.env.GATSBY_CHINA === 'true'
              ? `${process.env.APP_CHINA_URL}/signup/`
              : Utm(`${process.env.APP_URL}/signup/`),
          type: 'mainColor',
          extraClass: 'bigWidth',
        },
      },
      {
        headers: [
          {
            title: t('pricing.free'),
            subtitle: t('pricing.freeSubtitle'),
          },
          {
            title: t('pricing.growth'),
            subtitle: t('pricing.growthSubtitle'),
          },
          {
            title: t('pricing.premium'),
            subtitle: t('pricing.premiumSubtitle'),
          },
          {
            title: t('pricing.enterprise'),
            subtitle: t('pricing.enterpriseSubtitle'),
          },
        ],
        columns: {
          basic: {
            line1: true,
            line2: t('pricing.fivePosts'),
            line3: false,
            line4: false,
            line5: false,
            line6: false,
            line7: false,
            line8: false,
            line9: false,
            line11: false,
            line12: t('pricing.threeMonth'),
            line13: false,
            line14: false,
            line15: false,
            line16: false,
            line17: false,
            line19: t('pricing.twoColleagues'),
            line20: true,
            line21: false,
            line22: false,
            line23: t('pricing.free'),
            line24: t('pricing.freeMonthlyPrice'),
          },
          professional: {
            line1: true,
            line2: t('pricing.unlimited'),
            line3: t('pricing.ten'),
            line4: t('pricing.unlimited'),
            line5: true,
            line6: true,
            line7: t('pricing.growthBadge'),
            line8: t('pricing.good'),
            line9: false,
            line11: true,
            line12: t('pricing.unlimitedPriority'),
            line13: t('pricing.thirtyMonth'),
            line14: true,
            line15: true,
            line16: true,
            line17: true,
            line19: t('pricing.five'),
            line20: true,
            line21: true,
            line22: false,
            line23: t('pricing.growthPrice'),
            line24: t('pricing.growthMonthlyPrice'),
          },
          premium: {
            line1: true,
            line2: t('pricing.unlimited'),
            line3: t('pricing.thirty'),
            line4: t('pricing.unlimited'),
            line5: true,
            line6: true,
            line7: t('pricing.premiumBadge'),
            line8: t('pricing.high'),
            line9: false,
            line11: true,
            line12: t('pricing.unlimitedPriority'),
            line13: t('pricing.fortyFiveMonth'),
            line14: true,
            line15: true,
            line16: true,
            line17: true,
            line19: t('pricing.ten'),
            line20: true,
            line21: true,
            line22: t('pricing.two'),
            line23: t('pricing.premiumPrice'),
            line24: t('pricing.premiumMonthlyPrice'),
          },
          enterprise: {
            line1: true,
            line2: t('pricing.unlimited'),
            line3: t('pricing.forty'),
            line4: t('pricing.unlimited'),
            line5: true,
            line6: true,
            line7: t('pricing.enterpriseBadge'),
            line8: t('pricing.top'),
            line9: t('pricing.two'),
            line11: true,
            line12: t('pricing.unlimitedPriority'),
            line13: t('pricing.sixtyMonth'),
            line14: true,
            line15: true,
            line16: true,
            line17: true,
            line19: t('pricing.unlimited'),
            line20: true,
            line21: true,
            line22: t('pricing.four'),
            line23: t('pricing.enterprisePrice'),
            line24: t('pricing.enterpriseMonthlyPrice'),
          },
        },
        lines: [
          {
            isTitle: true,
            description: t('pricing.tableTitle1'),
          },
          {
            description: t('pricing.fabricLineDescription1'),
            descriptionTooltip: '',
          },
          {
            description: t('pricing.fabricLineDescription2'),
            descriptionTooltip: t('pricing.tooltipCatalogue'),
          },
          {
            description: t('pricing.fabricLineDescription3'),
            descriptionTooltip: t('pricing.tooltipPromotion'),
          },
          {
            description: t('pricing.fabricLineDescription4'),
            descriptionTooltip: t('pricing.tooltipShowrooms'),
          },
          {
            description: t('pricing.fabricLineDescription5'),
            descriptionTooltip: t('pricing.tooltipVideos'),
          },
          {
            description: t('pricing.fabricLineDescription6'),
            descriptionTooltip: t('pricing.tooltipWebsite'),
          },
          {
            description: t('pricing.fabricLineDescription7'),
            descriptionTooltip: t('pricing.tooltipBadge'),
          },
          {
            description: t('pricing.fabricLineDescription8'),
            descriptionTooltip: '',
          },
          {
            description: t('pricing.fabricLineDescription9'),
            descriptionTooltip: '',
          },
          {
            isTitle: true,
            description: t('pricing.tableTitle2'),
          },
          {
            description: t('pricing.fabricLineDescription10'),
            descriptionTooltip: '',
          },
          {
            description: t('pricing.fabricLineDescription11'),
            descriptionTooltip: '',
          },
          {
            description: t('pricing.fabricLineDescription12'),
            descriptionTooltip: t('pricing.tooltipBuyers'),
          },
          {
            description: t('pricing.fabricLineDescription13'),
            descriptionTooltip: t('pricing.tooltipProfiles'),
          },
          {
            description: t('pricing.fabricLineDescription14'),
            descriptionTooltip: '',
          },
          {
            description: t('pricing.fabricLineDescription15'),
            descriptionTooltip: '',
          },
          {
            description: t('pricing.fabricLineDescription17'),
            descriptionTooltip: t('pricing.tooltipDashboard'),
          },
          {
            isTitle: true,
            description: t('pricing.tableTitle3'),
          },
          {
            description: t('pricing.fabricLineDescription18'),
            descriptionTooltip: '',
          },
          {
            description: t('pricing.fabricLineDescription19'),
            descriptionTooltip: '',
          },
          {
            description: t('pricing.fabricLineDescription20'),
            descriptionTooltip: '',
          },
          {
            description: t('pricing.fabricLineDescription21'),
            descriptionTooltip: '',
          },
          {
            isTitle: true,
            pricing: true,
            description: t('pricing.tableTitle4'),
          },
          /*{
            isSubtitle: true,
            pricing: true,
            description: t("pricing.tableTitle5"),
          }*/
        ],
        cta: {
          text: t('menu.joinNow'),
          url:
            process.env.GATSBY_CHINA === 'true'
              ? `${process.env.APP_CHINA_URL}/signup/`
              : Utm(`${process.env.APP_URL}/signup/`),
          type: 'mainColor',
          extraClass: 'bigWidth',
        },
        titleSeparator: {
          text: {
            text: t('pricing.disclaimer'),
            colorClass: 'colorDarkLight',
            extraClass: 'text-center',
          },
          separator: true,
        },
      },
    ],
  };
};
