
// images
import TopBackground from "../images/pricing.jpg";

export const pageTexts = (t) => {
  return {
    topInfo: {
      title: {
        text: t("pricing.topTitle"),
        colorClass: "colorLight",
      },
      bottomDiagonal: true,
      opacity: true,
      image: {
        src: TopBackground,
        alt: t("pricing.imgAlt"),
        smallHeight: true
      },
      full: true,
    },
    plansTitle: {
      title: {
        text: t("pricing.choosePlan"),
        colorClass: "colorDark",
        extraClass: "text-center font28",
      },
      separator: false,
    },
  };
};
